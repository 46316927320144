import { useContext } from 'react';
import { UserContext } from '../../contexts/UserContext';
import { useTranslation } from 'react-i18next';
import Content from '../../components/Content/Content';
import { ReactComponent as ChatIcon } from '../../assets/icons/chats.svg';
import { ReactComponent as ProposalIcon } from '../../assets/icons/proposal.svg';
import Card from '../../components/Card/Card';
import OrderLine from '../../components/OrderLine/OrderLine';
import { ReactComponent as OrderIcon } from '../../assets/icons/order.svg';
import ProposalLine from '../../components/ProposalLine/ProposalLine';
import MessageLine from '../../components/MessageLine/MessageLine';
import CardLine from '../../components/CardLine/CardLine';

export default function HomePage() {
   const { deals, loadingDeals, loadingTickets, conversations, user } =
      useContext(UserContext);
   const { t } = useTranslation('home');

   return (
      <Content>
         <h1>{t('title', { name: user?.firstName })}</h1>

         <Card
            title={t('proposals')}
            action={{
               to: '/proposals',
               label: t('proposals_action'),
            }}
            icon={<ProposalIcon />}
            loading={loadingDeals}
         >
            {deals?.proposalDeals &&
               deals.proposalDeals.length > 0 &&
               deals.proposalDeals
                  .slice(0, 3)
                  .map((proposal) => (
                     <ProposalLine key={proposal.id} proposal={proposal} />
                  ))}

            {!deals?.proposalDeals && !loadingDeals && (
               <CardLine>{t('no_proposals')}</CardLine>
            )}
         </Card>

         <Card
            title={t('orders')}
            action={{
               to: '/orders',
               label: t('orders_action'),
            }}
            icon={<OrderIcon />}
            loading={loadingDeals}
         >
            {deals?.orderDeals &&
               deals.orderDeals.length > 0 &&
               deals.orderDeals
                  .slice(0, 3)
                  .map((order) => <OrderLine key={order.id} order={order} />)}

            {!deals?.orderDeals && !loadingDeals && (
               <CardLine>{t('no_orders')}</CardLine>
            )}
         </Card>

         <Card
            title={t('chats')}
            action={{
               to: '/messages',
               label: t('chats_action'),
            }}
            icon={<ChatIcon />}
            loading={loadingTickets}
         >
            {conversations?.conversationThreads &&
               conversations?.conversationThreads.length > 0 &&
               conversations?.conversationThreads
                  .slice(0, 3)
                  .map((message) => (
                     <MessageLine key={message.id} message={message} />
                  ))}

            {!conversations?.conversationThreads && !loadingTickets && (
               <CardLine>{t('no_chats')}</CardLine>
            )}
         </Card>
      </Content>
   );
}
