import { useTranslation } from 'react-i18next';
import styles from './ContactForm.module.scss';
import Button from '../../components/Button/Button';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg';
import { fetchApi } from '../../utils/fetchApi';
import { UserContext } from '../../contexts/UserContext';
import { useContext, useState } from 'react';
import Loading from '../Loading/Loading';

export default function ContactForm() {
   const { t } = useTranslation('support');
   const { user } = useContext(UserContext);
   const [loading, setLoading] = useState(false);
   const [sent, setSent] = useState(false);

   const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      const form = e.target as HTMLFormElement;
      const orderNumber = form.orderNumber.value;
      const message = form.message.value;

      setLoading(true);

      await fetchApi(`/api/logged-in-user/contact-form`, {
         method: 'POST',
         body: JSON.stringify({ orderNumber, message }),
      });

      setSent(true);
      setLoading(false);
   };

   if (sent)
      return (
         <div className={styles.sent}>
            <h2>{t('message_sent')}</h2>
            <p>{t('message_sent_text')}</p>
         </div>
      );

   if (loading) return <Loading />;

   return (
      <form onSubmit={handleSubmit}>
         <label htmlFor='orderNumber'>
            <strong>{t('order_number')}</strong>
         </label>
         <input
            id='orderNumber'
            type='text'
            required
            placeholder={t('order_number_placeholder')}
         />

         <label htmlFor='message'>
            <strong>{t('message')}</strong>
         </label>
         <textarea
            id='message'
            name='message'
            placeholder={t('message_placeholder')}
            rows={4}
            required
         ></textarea>

         <Button block type='submit'>
            {t('send_message')}
            <ArrowRightIcon />
         </Button>
      </form>
   );
}
