import { useTranslation } from 'react-i18next';
import { OrderLineItem } from '../../types/LineItems';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/arrow-down.svg';
import { ReactComponent as ClaimIcon } from '../../assets/icons/claim.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

import styles from './OrderLineItems.module.scss';
import { useContext, useState } from 'react';
import Button from '../Button/Button';
import { useParams } from 'react-router-dom';
import { ComplainContext } from '../../contexts/ComplainContext';
import { getProperty } from '../../utils/getProperty';
import { formatPrice } from '../../utils/formatPrice';
import Card from '../Card/Card';
import CardLine from '../CardLine/CardLine';
import { UserContext } from '../../contexts/UserContext';

interface Props {
   currency: string;
   lineItems: OrderLineItem[];
   selectable?: boolean;
   hideActions?: boolean;
}

export default function OrderLineItems({
   lineItems,
   selectable,
   hideActions,
   currency,
}: Props) {
   const [isExpanded, setIsExpanded] = useState<number | null>(null);

   const {
      selectedOrderLines,
      toggleSelectedOrderLine,
      setSelectedOrderLines,
   } = useContext(ComplainContext);

   let { id } = useParams();

   const { loadingLineItems } = useContext(UserContext);

   const { t } = useTranslation('orders');

   const toggle = (id: number) => {
      if (isExpanded === id) {
         setIsExpanded(null);
      } else {
         setIsExpanded(id);
      }
   };

   const allIds = lineItems.map((item) => item.id);

   const handleSelectAll = () => {
      if (selectedOrderLines.length === allIds.length) {
         setSelectedOrderLines([]);
         return;
      }

      setSelectedOrderLines(allIds);
   };

   return (
      <Card
         title={t('elements')}
         loading={loadingLineItems}
         action={
            selectable
               ? {
                    label: t(
                       selectedOrderLines.length === allIds.length
                          ? 'deselect_all'
                          : 'select_all'
                    ),
                    onClick: handleSelectAll,
                 }
               : {
                    label: t('complain_element'),
                    to: `/orders/${id}/complain/`,
                 }
         }
      >
         {lineItems.map((orderLineItem) => (
            <CardLine key={orderLineItem.id}>
               <div key={orderLineItem.id}>
                  <div className={styles.header}>
                     <strong>{orderLineItem.name}</strong>
                     <strong>
                        {orderLineItem.quantity ? orderLineItem.quantity : '-'}{' '}
                        {t('amount')}
                     </strong>

                     <strong>
                        {orderLineItem.priceNonDiscounted !==
                           orderLineItem.price && (
                           <div className={styles.discounted}>
                              {formatPrice(orderLineItem.price, currency)}
                           </div>
                        )}
                        <div className={styles.price}>
                           {formatPrice(
                              orderLineItem.priceNonDiscounted,
                              currency
                           )}
                        </div>
                     </strong>

                     {!hideActions && selectable && (
                        <Button
                           variant={
                              selectedOrderLines.includes(orderLineItem.id)
                                 ? 'grey'
                                 : 'secondary'
                           }
                           small
                           onClick={() =>
                              toggleSelectedOrderLine(orderLineItem.id)
                           }
                        >
                           {t(
                              selectedOrderLines.includes(orderLineItem.id)
                                 ? 'complain_cancel'
                                 : 'complain_element'
                           )}

                           {selectedOrderLines.includes(orderLineItem.id) ? (
                              <CloseIcon />
                           ) : (
                              <ClaimIcon />
                           )}
                        </Button>
                     )}
                  </div>

                  <div>
                     {t('width')}:{' '}
                     {
                        getProperty(
                           'width',
                           orderLineItem.variousProductProperties
                        )?.valueLocalized
                     }
                     {' • '}
                     {t('height')}:{' '}
                     {
                        getProperty(
                           'height',
                           orderLineItem.variousProductProperties
                        )?.valueLocalized
                     }
                     {' • '}
                     {t('sku')}: {orderLineItem.sku}
                  </div>

                  <button
                     type='button'
                     className={`${styles.toggle} ${isExpanded === orderLineItem.id && styles.active}`}
                     onClick={() => toggle(orderLineItem.id)}
                  >
                     {t('see_specifications')}

                     <ArrowDownIcon />
                  </button>
               </div>

               {isExpanded === orderLineItem.id && (
                  <div className={styles.line}>
                     <div className={styles.specifications}>
                        {orderLineItem.variousProductProperties.map(
                           (property, index) => {
                              if (!property.valueLocalized) return null;

                              return (
                                 <div key={index} className={styles.spec}>
                                    {property.nameLocalized}
                                    {': '}
                                    <strong>{property.valueLocalized}</strong>
                                 </div>
                              );
                           }
                        )}
                     </div>
                  </div>
               )}
            </CardLine>
         ))}
      </Card>
   );
}
