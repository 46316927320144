import { Link, useParams } from 'react-router-dom';

import styles from './MessageListItem.module.scss';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../utils/formatDate';
import { ConversationThread } from '../../types/Conversations';

interface MessageListItemProps {
   message: ConversationThread;
}

export default function MessageListItem({ message }: MessageListItemProps) {
   let { id } = useParams();
   const { t } = useTranslation('messages');

   const getLatestMessageTime = (time1: string, time2: string) => {
      const latestMessageTime =
         new Date(time1) > new Date(time2) ? time1 : time2;

      const daysSinceLatestMessage = Math.floor(
         (new Date().getTime() - new Date(latestMessageTime).getTime()) /
            (1000 * 60 * 60 * 24)
      );

      if (daysSinceLatestMessage === 0) {
         return t('today');
      } else if (daysSinceLatestMessage === 1) {
         return t('yesterday');
      } else {
         return `${daysSinceLatestMessage} ${t('days_ago')}`;
      }
   };

   return (
      <Link
         key={message.id}
         className={`${styles.thread} ${id === message.id.toString() && styles.active}`}
         to={`/messages/${message.id}`}
      >
         <div className={styles.title}>
            #{message.id}: {message.status.toLowerCase()}
         </div>
         <div className={styles.footer}>
            <div>
               <strong>{t('created_at')}</strong>:{' '}
               {formatDate(message.createdAt, true)}
            </div>
            <div className={styles.info}>
               {getLatestMessageTime(
                  message.latestMessageSentTimestamp,
                  message.latestMessageReceivedTimestamp
               )}
            </div>
         </div>
      </Link>
   );
}
