import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import da from './locales/da.json';
import at from './locales/at.json';
import de from './locales/de.json';
import fi from './locales/fi.json';
import nl from './locales/nl.json';
import no from './locales/no.json';
import se from './locales/se.json';

i18n.use(initReactI18next).init({
   resources: {
      at,
      da,
      de,
      en,
      fi,
      nl,
      no,
      se,
   },
   fallbackLng: 'en',
   debug: true,
   keySeparator: false,
   interpolation: {
      escapeValue: false,
   },
});

export default i18n;
