import { useTranslation } from 'react-i18next';
import { UserContext } from '../../contexts/UserContext';
import { useContext } from 'react';
import OrderLine from '../../components/OrderLine/OrderLine';
import Loading from '../../components/Loading/Loading';
import Content from '../../components/Content/Content';
import Card from '../../components/Card/Card';
import { ReactComponent as OrderIcon } from '../../assets/icons/order.svg';
import CardLine from '../../components/CardLine/CardLine';

export default function OrdersPage() {
   const { t } = useTranslation('orders');

   const { deals, loadingDeals } = useContext(UserContext);

   const orders = deals?.orderDeals;

   const ordersInProgress = orders?.filter(
      (order) => order.orderDetails.currentStage === 'Pending'
   );

   const previousOrders = orders?.filter(
      (order) => order.orderDetails.currentStage !== 'Pending'
   );

   return (
      <Content>
         <h1>{t('title')}</h1>

         {loadingDeals ? (
            <Loading />
         ) : (
            <div>
               <Card title={t('orders_in_progress')} icon={<OrderIcon />}>
                  {ordersInProgress != null && ordersInProgress.length > 0 ? (
                     ordersInProgress.map((order) => (
                        <OrderLine key={order.id} order={order} />
                     ))
                  ) : (
                     <CardLine>
                        {!loadingDeals && t('no_orders_in_progress')}
                     </CardLine>
                  )}
               </Card>

               <Card title={t('previous_orders')} icon={<OrderIcon />}>
                  {previousOrders != null && previousOrders.length > 0 ? (
                     previousOrders.map((order) => (
                        <OrderLine key={order.id} order={order} />
                     ))
                  ) : (
                     <CardLine>
                        {!loadingDeals && t('no_previous_orders')}
                     </CardLine>
                  )}
               </Card>
            </div>
         )}
      </Content>
   );
}
