import { OrderStage, OrderStageHistoryItem } from '../../types/Deal';
import { formatDate } from '../../utils/formatDate';
import { useTranslation } from 'react-i18next';
import Progress from '../Progress/Progress';

interface Props {
   stages: OrderStageHistoryItem[];
}

export default function OrderStages({ stages }: Props) {
   const { t } = useTranslation('orders');

   const standardProcess: OrderStage[] = [
      'Open Orders',
      'Pending',
      'Expired Quotes',
      'Quote',
      'In Production',
      'Shipping',
      'Delivered',
   ];

   const lastStage = stages[stages.length - 1];

   const lastStageIndex = standardProcess.indexOf(lastStage.stage);

   let sortedStages = [];

   if (lastStageIndex === -1) {
      sortedStages = stages;
   }

   sortedStages = stages.concat(
      standardProcess
         .slice(lastStageIndex + 1)
         .map((stage) => ({ stage, time: '' }))
   );

   const steps = sortedStages.map((stage) => ({
      title: t(`status_${stage.stage}`),
      description: formatDate(stage.time),
   }));

   return <Progress steps={steps} activeIndex={stages.length - 1} />;
}
