import { Link, useParams } from 'react-router-dom';

import styles from './MessageLine.module.scss';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../utils/formatDate';
import { ConversationThread } from '../../types/Conversations';
import CardLine from '../CardLine/CardLine';

interface MessageLineProps {
   message: ConversationThread;
}

export default function MessageLine({ message }: MessageLineProps) {
   let { id } = useParams();
   const { t } = useTranslation('messages');

   const getLatestMessageTime = (time1: string, time2: string) => {
      const latestMessageTime =
         new Date(time1) > new Date(time2) ? time1 : time2;

      const daysSinceLatestMessage = Math.floor(
         (new Date().getTime() - new Date(latestMessageTime).getTime()) /
            (1000 * 60 * 60 * 24)
      );

      if (daysSinceLatestMessage === 0) {
         return t('today');
      } else if (daysSinceLatestMessage === 1) {
         return t('yesterday');
      } else {
         return `${daysSinceLatestMessage} ${t('days_ago')}`;
      }
   };

   return (
      <CardLine key={message.id} to={`/messages/${message.id}`}>
         <strong>
            #{message.id}: {message.status.toLowerCase()}
         </strong>

         <div className={styles.content}>
            <div>{formatDate(message.createdAt, true)}</div>
            <div className={styles.info}>
               {getLatestMessageTime(
                  message.latestMessageSentTimestamp,
                  message.latestMessageReceivedTimestamp
               )}
            </div>
         </div>
      </CardLine>
   );
}
