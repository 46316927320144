import { Link } from 'react-router-dom';
import styles from './Button.module.scss';

interface Props {
   children: React.ReactNode;
   onClick?: () => void;
   to?: string;
   variant?: 'primary' | 'secondary' | 'grey' | 'ghost';
   small?: boolean;
   target?: string;
   block?: boolean;
   disabled?: boolean;
   type?: 'submit' | 'button';
   className?: string;
}

export default function Button({
   to,
   children,
   onClick,
   variant,
   target,
   block,
   small,
   disabled,
   type = 'button',
   className,
}: Props) {
   const classList = `
      ${className}
      ${styles.button} 
      ${variant ? styles[variant] : ''} 
      ${block ? styles.block : ''} 
      ${small ? styles.small : ''}
      `;

   if (to) {
      return (
         <Link to={to} className={classList} target={target}>
            {children}
         </Link>
      );
   }

   return (
      <button
         onClick={onClick}
         className={classList}
         type={type}
         disabled={disabled}
      >
         {children}
      </button>
   );
}
